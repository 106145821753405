<template>
  <section :class="['rankingRoom', isLoading && 'skeletonS']">
    <template v-if="!isLoading">
      <iconic class="rankingRoom__iconRoom" name="roomOcupation" />
      <span class="rankingRoom__location">{{ `${data.roomName} - ${data.officeName}` }}</span>
      <p class="rankingRoom__city">{{ data.cityName }}</p>
      <div class="rankingRoom__barShow">
        <div class="rankingRoom__barShow rankingRoom__barShow--fill" :style="{ width: data.percentage + '%' }"></div>
      </div>
      <h3 class="rankingRoom__percentage">{{ data.percentage + "%" }}</h3>
    </template>
  </section>
</template>

<script>
import iconic from "@/plugins/iconic/iconic.vue";
export default {
  props: ["isLoading", "data"],
};
</script>

<style lang="scss">
.rankingRoom {
  display: grid;
  align-content: center;
  align-items: center;
  grid-template-columns: 20px auto auto auto 40px;
  gap: 5px;
  height: 48px;
  width: 100%;
  padding: 3px 10px;
  border-radius: 50px;
  background: #f3f3f3;
  &__iconRoom {
    font-size: 15px;
  }
  &__location {
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__city {
    text-align: center;
    font-size: 12px;
  }
  &__barShow {
    height: 6px;
    width: 100%;
    min-width: 50px;
    border-radius: 7px;
    background-color: #ccc7c7;
    &--fill {
      height: 100%;
      width: 0;
      min-width: 0;
      max-width: 100%;
      background-color: $primary-color;
      transition: 1s ease-in-out;
    }
  }
  &__percentage {
    text-align: end;
    font-size: 16px;
  }
  @media screen and (min-width: $tablet_width) {
    grid-template-columns: 30px auto auto auto 40px;
    padding: 3px 10px;
    &__iconRoom {
      font-size: 17px;
    }
    &__city {
      font-size: 13px;
    }
    &__percentage {
      font-size: 20px;
    }
  }
}
</style>
